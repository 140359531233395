import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import { reducer as historyNavigationReducer } from 'src/Areas/Genericos/GE/slices/historyNavigation';
import { reducer as accountReducer } from 'src/Areas/Genericos/GE/slices/account';
import { reducer as kanbanReducer } from 'src/Areas/Servicos/GS/slices/kanban';
import { reducer as ordemProducaoReducer } from 'src/Areas/Suprimentos/PC/slices/ordemProducao';

export const rootReducer = combineReducers({
  form: formReducer,
  historyNavigation: historyNavigationReducer,
  account: accountReducer,
  kanban: kanbanReducer,
  ordemProducao: ordemProducaoReducer,
});
